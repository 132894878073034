import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


    
 
class TermsVipCmp extends Component {    

    render() {
        const {            
            titleIcon=<span><strong><br/>Términos y Condiciones de la Oferta</strong><br/></span>,
            txtTitle=<span><br/>*Sujeto a aprobación de crédito. Esta oferta se hace únicamente al titular de la cuenta y excluye a cualquier cosignatario. Si su cuenta se creó con un codeudor, también se requerirá su firma. Esto no es una garantía de préstamo. **El tiempo de respuesta depende de la presentación de todos los documentos requeridos. La APR puede variar según factores como la primera fecha de pago, el plazo de pago y el monto del préstamo. La nueva oferta puede incluir una comisión de $75. Préstamos hechos o arreglados de conformidad con el Departamento de Supervisión Comercial. Licencia de prestamistas financieros de California No. 6054790.<br/><br/></span>,
            txtTitleEmp='',
                    
            
                    } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                    <br/>
                                           
                    </CardElevation>
                    <br/><br/>
                    
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

TermsVipCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    
    txtTitleEmp: PropTypes.string,
    
    
    
        
}

export default withThemeProps(TermsVipCmp, 'VDPNTermsVip')






